import { createSlice } from '@reduxjs/toolkit'
const initCheckout = {
  shipping_method: '',
  shipping_name: '',
  shipping_last_name: '',
  shipping_address: '',
  shipping_address2: '',
  shipping_country: '',
  shipping_state: '',
  shipping_city: '',
  shipping_zip: '',
  phone: '',
  email: '',
  consent: true,
  billing_name: '',
  billing_last_name: '',
  billing_address: '',
  billing_address2: '',
  billing_country: '',
  billing_state: '',
  billing_city: '',
  billing_zip: '',
  payment_method: '',
  coupon_code: '',
  order_id: '',
  order_uid: '',
  order_url: '',
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initCheckout,
  reducers: {
    updateCheckoutField: (state, action) => {
      const { name, value } = action.payload
      state[name] = value
    },
    resetShipping: state => {
      state.shipping_name = ''
      state.shipping_last_name = ''
      state.shipping_address = ''
      state.shipping_address2 = ''
      state.shipping_country = ''
      state.shipping_state = ''
      state.shipping_city = ''
      state.shipping_zip = ''
    },
    resetBilling: state => {
      state.billing_name = ''
      state.billing_last_name = ''
      state.billing_address = ''
      state.billing_address2 = ''
      state.billing_country = ''
      state.billing_state = ''
      state.billing_city = ''
      state.billing_zip = ''
      state.phone = ''
      state.email = ''
    },
    updateOrderId: (state, action) => {
      const { id, uid, url } = action.payload
      state.order_id = id
      state.order_uid = uid
      state.order_url = url
    },
    resetCheckout: () => {
      return initCheckout
    },
    copyShippingToBilling: state => {
      state.billing_name = state.shipping_name
      state.billing_last_name = state.shipping_last_name
      state.billing_address = state.shipping_address
      state.billing_address2 = state.shipping_address2
      state.billing_country = state.shipping_country
      state.billing_state = state.shipping_state
      state.billing_city = state.shipping_city
      state.billing_zip = state.shipping_zip
    },
    copyBillingToShipping: state => {
      state.shipping_name =      state.billing_name
      state.shipping_last_name = state.billing_last_name
      state.shipping_address =   state.billing_address
      state.shipping_address2 =  state.billing_address2
      state.shipping_country =   state.billing_country
      state.shipping_state =     state.billing_state
      state.shipping_city =      state.billing_city
      state.shipping_zip =       state.billing_zip
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateCheckoutField,
  resetCheckout,
  copyShippingToBilling,
  copyBillingToShipping,
  resetBilling,
  resetShipping,
  updateOrderId,
} = checkoutSlice.actions
export { initCheckout }
export default checkoutSlice.reducer
